import { graphql } from "gatsby"
import React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import { RenderComponent } from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

import { useTranslation } from "react-i18next"
import Button from "../components/01_atoms/Button/Button"
import Spacer from "../components/01_atoms/Spacer/Spacer"
import Section from "../components/03_organisms/Section/Section"
import { Helmet, useI18next } from "gatsby-plugin-react-i18next"
import Back from "../components/01_atoms/Back/Back"

const LocationPage = ({ data, pageContext, location }) => {
  const pageData = data.nodeLocation
  const components = pageData?.relationships.field_components
  const jobData = pageContext.jobData?.attributes
  const { t } = useTranslation()

  return (
    <Layout>
      {jobData && pageData && (
        <>
          <Seo
            title={jobData.google_for_jobs?.title}
            description={jobData.google_for_jobs?.description}
          />
          <Helmet
            bodyAttributes={{
              class: "jobs-page",
            }}
          />
          <Section content background="Azure">
            <Spacer height={50} />

            {location.state?.prevPath && (
              <>
                <Back to={`${location.state.prevPath}#jobs`}>
                  {t("location_back")}
                </Back>
                <Spacer height={32} />
              </>
            )}

            {jobData.description_title && (
              <Heading size="xxl" spacing="l">
                {jobData.description_title}
              </Heading>
            )}
            {jobData.description && (
              <Paragraph>
                <span
                  dangerouslySetInnerHTML={{
                    __html: jobData.description,
                  }}
                />
              </Paragraph>
            )}

            {jobData.profile_title && (
              <Heading size="l" spacing="l">
                {jobData.profile_title}
              </Heading>
            )}
            {jobData.profile && (
              <Paragraph>
                <span
                  dangerouslySetInnerHTML={{
                    __html: jobData.profile,
                  }}
                />
              </Paragraph>
            )}

            {jobData.offer_title && (
              <Heading size="l" spacing="l">
                {jobData.offer_title}
              </Heading>
            )}
            {jobData.profile && (
              <Paragraph>
                <span
                  dangerouslySetInnerHTML={{
                    __html: jobData.offer,
                  }}
                />
              </Paragraph>
            )}

            {jobData.apply_url && (
              <Button type="link" to={jobData.apply_url}>
                {t("job_apply_button")}
              </Button>
            )}
          </Section>

          <Section content>
            <Heading size="xl" align="center">
              {pageData.field_title_components}
            </Heading>
          </Section>
          {components?.map((component, index) =>
            RenderComponent(component, index)
          )}
        </>
      )}
    </Layout>
  )
}

export default LocationPage

export const query = graphql`
  query ($language: String!, $country: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    nodeLocation(
      relationships: { field_country: { name: { eq: $country } } }
      langcode: { eq: $language }
      status: { eq: true }
    ) {
      title
      body {
        processed
      }
      field_meta_description
      field_meta_title
      field_title_components
      field_title_job_opportunities
      relationships {
        field_image {
          ...imageFragment
        }
        field_country {
          name
          field_country
          relationships {
            field_background_color {
              name
            }
            field_image {
              ...imageFragment
            }
          }
        }
        field_components {
          ... on Node {
            ...carouselFragment
            ...imageTextFragment
            ...testimonialsCarousel
            ...videoFragment
          }
        }
      }
    }

    allNodeLocation(
      filter: { status: { eq: true }, langcode: { eq: $language } }
    ) {
      nodes {
        path {
          alias
          langcode
        }
        relationships {
          field_country {
            name
            field_country
            relationships {
              field_background_color {
                name
              }
              field_image {
                ...imageFragment
              }
            }
          }
        }
      }
    }
  }
`
