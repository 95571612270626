import { Link } from "gatsby"
import React from "react"
import * as styles from "./back.module.scss"

const Back = ({ to, children }) => {
  return (
    <Link className={styles.back} to={to}>
      {children}
    </Link>
  )
}

export default Back
